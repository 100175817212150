import { PaginationMetaData } from 'types/pagination';

// PAGE PATH
export const PAGE_PATH = '/payments/bank_bins_blacklist';

// Bank Bins Blacklist list
export const BANK_BINS_BLACKLIST_LIST_LOAD = 'BANK_BINS_BLACKLIST_LIST_LOAD';
export const BANK_BINS_BLACKLIST_LIST_SUCCESS = 'BANK_BINS_BLACKLIST_SUCCESS';
export const BANK_BINS_BLACKLIST_LIST_ERROR = 'BANK_BINS_BLACKLIST_ERROR';

// Bank Bins Blacklist current item
export const BANK_BINS_BLACKLIST_CURRENT_LOAD =
  'BANK_BINS_BLACKLIST_CURRENT_LOAD';
export const BANK_BINS_BLACKLIST_CURRENT_SUCCESS =
  'BANK_BINS_BLACKLIST_CURRENT_SUCCESS';
export const BANK_BINS_BLACKLIST_CURRENT_SAVE =
  'BANK_BINS_BLACKLIST_CURRENT_SAVE';
export const BANK_BINS_BLACKLIST_CURRENT_SAVE_SUCCESS =
  'BANK_BINS_BLACKLIST_CURRENT_SAVE_SUCCESS';
export const BANK_BINS_BLACKLIST_CURRENT_UPDATE_SUCCESS =
  'BANK_BINS_BLACKLIST_CURRENT_UPDATE_SUCCESS';
export const BANK_BINS_BLACKLIST_CURRENT_SAVE_ERROR =
  'BANK_BINS_BLACKLIST_CURRENT_SAVE_ERROR';

export const BANK_BINS_BLACKLIST_CURRENT_DELETE_SUCCESS =
  'BANK_BINS_BLACKLIST_CURRENT_DELETE_SUCCESS';

export interface BankBinsBlacklistResponse {
  data: BankBinsBlacklistListItem[];
  meta: PaginationMetaData;
}

interface BankBinsBlacklistListLoadAction {
  type: typeof BANK_BINS_BLACKLIST_LIST_LOAD;
}

interface BankBinsBlacklistListSuccess {
  type: typeof BANK_BINS_BLACKLIST_LIST_SUCCESS;
  payload: BankBinsBlacklistResponse;
}

interface BankBinsBlacklistListError {
  type: typeof BANK_BINS_BLACKLIST_LIST_ERROR;
}

interface BankBinsBlacklistCurrentLoadAction {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_LOAD;
}

interface BankBinsBlacklistCurrentDeleteAction {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_DELETE_SUCCESS;
}

interface BankBinsBlacklistCurrentSuccess {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_SUCCESS;
  data: BankBinsBlacklistCurrentResponse;
}

interface BankBinsBlacklistCurrentSaveAction {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_SAVE;
}

interface BankBinsBlacklistCurrentSaveSuccess {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_SAVE_SUCCESS;
  data: BankBinsBlacklistCurrentResponse;
}

interface BankBinsBlacklistCurrentUpdateSuccess {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_UPDATE_SUCCESS;
  data: BankBinsBlacklistCurrentResponse;
}

interface BankBinsBlacklistCurrentSaveError {
  type: typeof BANK_BINS_BLACKLIST_CURRENT_SAVE_ERROR;
  data: Error;
}

export type BankBinsBlacklistFilters = {
  page: number | string;
};

export interface BankBinsBlacklistListItem {
  id: number | string;
  bank_name: string;
  bin: number | null;
  created_at: string;
  updated_at: string;
}

export interface BankBinsBlacklistForm {
  bank_name: string;
  bin?: number | null;
}

export interface BankBinsBlacklistCurrentResponse {
  data: BankBinsBlacklistListItem;
}

export interface BankBinsBlacklistState {
  isLoading: boolean;
  hasError: boolean;
  listItems: BankBinsBlacklistResponse;
  current: BankBinsBlacklistCurrentResponse;
  actionState: {
    bankBinsBlacklistSave: {
      isLoading: boolean;
      hasCreated: boolean;
      hasUpdated: boolean;
      hasDeleted: boolean;
      error?: Error;
    };
  };
}

export type BankBinsBlacklistActionTypes =
  | BankBinsBlacklistListLoadAction
  | BankBinsBlacklistListSuccess
  | BankBinsBlacklistListError
  | BankBinsBlacklistCurrentLoadAction
  | BankBinsBlacklistCurrentSuccess
  | BankBinsBlacklistCurrentSaveAction
  | BankBinsBlacklistCurrentSaveError
  | BankBinsBlacklistCurrentDeleteAction
  | BankBinsBlacklistCurrentUpdateSuccess
  | BankBinsBlacklistCurrentSaveSuccess;

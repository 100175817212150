import {
  BANK_BINS_BLACKLIST_CURRENT_LOAD,
  BANK_BINS_BLACKLIST_CURRENT_SAVE,
  BANK_BINS_BLACKLIST_CURRENT_SAVE_ERROR,
  BANK_BINS_BLACKLIST_CURRENT_SAVE_SUCCESS,
  BANK_BINS_BLACKLIST_CURRENT_UPDATE_SUCCESS,
  BANK_BINS_BLACKLIST_CURRENT_SUCCESS,
  BANK_BINS_BLACKLIST_CURRENT_DELETE_SUCCESS,
  BANK_BINS_BLACKLIST_LIST_ERROR,
  BANK_BINS_BLACKLIST_LIST_LOAD,
  BANK_BINS_BLACKLIST_LIST_SUCCESS,
  BankBinsBlacklistActionTypes,
  BankBinsBlacklistState,
} from './types';

const initialState: BankBinsBlacklistState = {
  isLoading: false,
  hasError: false,
  actionState: {
    bankBinsBlacklistSave: {
      isLoading: false,
      hasCreated: false,
      hasUpdated: false,
      hasDeleted: false,
    },
  },
  listItems: {
    data: [],
    meta: {
      current_page: 1,
      from: 0,
      to: 0,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  current: {
    data: {
      id: '',
      bank_name: '',
      bin: null,
      created_at: '',
      updated_at: '',
    },
  },
};

const bankBinsBlacklistListReducer = (
  state = initialState,
  action: BankBinsBlacklistActionTypes
): BankBinsBlacklistState => {
  const defaultState = {
    isLoading: false,
    hasUpdated: false,
    hasCreated: false,
    hasDeleted: false,
  };

  switch (action.type) {
    case BANK_BINS_BLACKLIST_LIST_LOAD:
      return {
        ...state,
        current: initialState.current,
        isLoading: true,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
          },
        },
      };

    case BANK_BINS_BLACKLIST_CURRENT_LOAD:
    case BANK_BINS_BLACKLIST_CURRENT_SAVE:
      return {
        ...state,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            isLoading: true,
          },
        },
      };
    case BANK_BINS_BLACKLIST_CURRENT_DELETE_SUCCESS:
      return {
        ...state,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            hasDeleted: true,
          },
        },
      };
    case BANK_BINS_BLACKLIST_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listItems: action.payload,
      };

    case BANK_BINS_BLACKLIST_CURRENT_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            error: undefined,
          },
        },
      };

    case BANK_BINS_BLACKLIST_CURRENT_SAVE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            hasCreated: true,
            error: undefined,
          },
        },
      };

    case BANK_BINS_BLACKLIST_CURRENT_UPDATE_SUCCESS:
      return {
        ...state,
        current: action.data,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            hasUpdated: true,
            error: undefined,
          },
        },
      };

    case BANK_BINS_BLACKLIST_CURRENT_SAVE_ERROR:
      return {
        ...state,
        actionState: {
          bankBinsBlacklistSave: {
            ...defaultState,
            error: action.data,
          },
        },
      };

    case BANK_BINS_BLACKLIST_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default bankBinsBlacklistListReducer;

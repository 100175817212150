import { PaginationMetaData } from 'types/pagination';

// PAGE PATH
export const PAGE_PATH = '/payments/payment_provider_rules';

// Payment Provider Rule list
export const PAYMENT_PROVIDER_RULE_LIST_LOAD =
  'PAYMENT_PROVIDER_RULE_LIST_LOAD';
export const PAYMENT_PROVIDER_RULE_LIST_SUCCESS =
  'PAYMENT_PROVIDER_RULE_SUCCESS';
export const PAYMENT_PROVIDER_RULE_LIST_ERROR = 'PAYMENT_PROVIDER_RULE_ERROR';

// Payment Provider Rule current item
export const PAYMENT_PROVIDER_RULE_CURRENT_LOAD =
  'PAYMENT_PROVIDER_RULE_CURRENT_LOAD';
export const PAYMENT_PROVIDER_RULE_CURRENT_SUCCESS =
  'PAYMENT_PROVIDER_RULE_CURRENT_SUCCESS';
export const PAYMENT_PROVIDER_RULE_CURRENT_ERROR =
  'PAYMENT_PROVIDER_RULE_CURRENT_ERROR';

// Early warning current list item save
export const PAYMENT_PROVIDER_RULE_CURRENT_SAVE =
  'PAYMENT_PROVIDER_RULE_CURRENT_SAVE';
export const PAYMENT_PROVIDER_RULE_CURRENT_SAVE_SUCCESS =
  'PAYMENT_PROVIDER_RULE_CURRENT_SAVE_SUCCESS';
export const PAYMENT_PROVIDER_RULE_CURRENT_SAVE_ERROR =
  'PAYMENT_PROVIDER_RULE_CURRENT_SAVE_ERROR';

export interface PaymentProviderRuleResponse {
  data: PaymentProviderRuleListItem[];
  meta: PaginationMetaData;
}

interface PaymentProviderRuleListLoadAction {
  type: typeof PAYMENT_PROVIDER_RULE_LIST_LOAD;
}

interface PaymentProviderRuleListSuccess {
  type: typeof PAYMENT_PROVIDER_RULE_LIST_SUCCESS;
  payload: PaymentProviderRuleResponse;
}

interface PaymentProviderRuleListError {
  type: typeof PAYMENT_PROVIDER_RULE_LIST_ERROR;
}

interface PaymentProviderRuleCurrentLoadAction {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_LOAD;
}

interface PaymentProviderRuleCurrentSuccess {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_SUCCESS;
  data: PaymentProviderRuleCurrentResponse;
}

interface PaymentProviderRuleCurrentError {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_ERROR;
}

interface PaymentProviderRuleCurrentSaveAction {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_SAVE;
}

interface PaymentProviderRuleCurrentSaveSuccess {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_SAVE_SUCCESS;
  data: PaymentProviderRuleCurrentResponse;
}

interface PaymentProviderRuleCurrentSaveError {
  type: typeof PAYMENT_PROVIDER_RULE_CURRENT_SAVE_ERROR;
  data: Error;
}

export type PaymentProviderRuleFilters = {
  page: number | string;
  error?: string;
  type?: string;
  delay_days?: string;
};

export interface PaymentProviderRuleListItem {
  id: number | string;
  error: string;
  type: string | null;
  delay_days: number | null;
  created_at: string;
  updated_at: string;
}

export interface PaymentProviderRuleForm {
  error: string;
  type: string | null;
  delay_days?: number | undefined;
}

export interface PaymentProviderRuleCurrentResponse {
  data: PaymentProviderRuleListItem;
}

export interface PaymentProviderRuleState {
  isLoading: boolean;
  hasError: boolean;
  listItems: PaymentProviderRuleResponse;
  current: PaymentProviderRuleCurrentResponse;
  actionState: {
    paymentProviderRuleSave: {
      isLoading: boolean;
      hasUpdated: boolean;
      error?: Error;
    };
  };
}

export type PaymentProviderRuleActionTypes =
  | PaymentProviderRuleListLoadAction
  | PaymentProviderRuleListSuccess
  | PaymentProviderRuleListError
  | PaymentProviderRuleCurrentLoadAction
  | PaymentProviderRuleCurrentSuccess
  | PaymentProviderRuleCurrentError
  | PaymentProviderRuleCurrentSaveAction
  | PaymentProviderRuleCurrentSaveError
  | PaymentProviderRuleCurrentSaveSuccess;
